.vacancies-list-replace {
  .text-block-13 {
    text-decoration: none;
  }

  .image-9 {
    justify-self: end;
  }

  .vector, .comp-name, .button-referal, .online {
    align-self: end;
  }

  .parametr {
    -ms-grid-columns: 1.5fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  }
}

.text-block-19-mt-0, .vaca-about {
  a {
    color: #00b8e3;
  }
}

.recruiter {
  .contact-info-link {
    cursor: pointer;
  }
  .avatar {
    margin-right: auto;
    margin-left: auto;
  }
}